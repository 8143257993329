html {
  overflow: hidden; }

.ui.teal.button,
.ui.teal.buttons .button,
.ui.teal.button:focus,
.ui.teal.buttons .button:focus {
  background-color: #0070ba; }

.ui.teal.button:hover,
.ui.teal.buttons .button:hover {
  background-color: #0070ba; }

.ui.teal.header {
  color: #0070ba !important; }

a,
a:hover,
a:active {
  color: #0070ba; }

.ui.segments {
  border-radius: 12px; }

.ui.segments:not(.horizontal) > .segment:first-child {
  border-radius: 12px 12px 0 0; }

.ui.segments:not(.horizontal) > .segment:last-child {
  border-radius: 0 0 12px 12px; }

.ui .message {
  background-color: #ffffff;
  border-radius: 12px; }

div.navigation.ui.segment {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.025rem;
  text-decoration: none;
  background-color: #fff;
  color: #0070ba !important;
  padding: 0.5em 5%;
  margin-bottom: 28px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; }
  div.navigation.ui.segment .ui.menu {
    height: 29px; }

iframe {
  z-index: 1 !important; }

.ui.menu .item,
.ui.secondary.menu .active.item,
.ui.secondary.menu a.item:hover {
  color: #0070ba;
  background-color: #fff; }

.ui.secondary.menu a.item:hover {
  border-bottom: 1px solid #0070ba;
  border-radius: 0; }

.ui.navigation .ui.menu a.item {
  margin: 0 13px;
  padding: 10px 2px; }

.right.menu .item.click.bell {
  margin: 0 !important; }

.ui.navigation .ui.menu a.item.active,
.ui.navigation .ui.menu a.item.active:hover {
  border-bottom: 1px solid #0070ba;
  color: #0070ba;
  background-color: #fff;
  border-radius: 0;
  margin: 0 14px;
  padding: 10px 2px; }

.ui.navigation .ui.menu .dropdown {
  color: #0070ba; }

.home {
  min-width: 850px;
  max-width: 80%;
  width: 80%;
  margin: 0 auto !important; }

.home .ui.header {
  /*color: #0070ba;*/ }

.ui.raised.segment.navigation {
  border-radius: 0px; }

.ui.center.aligned.segment.content {
  border: none;
  background: none;
  box-shadow: none;
  padding-top: 0;
  /*max-width: 80%;*/
  width: 100%;
  margin: 0 auto 1rem;
  padding: 68px 0 0px 0; }

.ui.center.aligned.segment.content table {
  background: #fff;
  padding: 14px;
  border-radius: 12px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  width: calc(100%); }

.ui.navigation .ui.menu .item {
  font-size: 15px; }

.ui.navigation .ui.menu .item.balance {
  color: #000000; }

button.ui.button.paypal:hover {
  background: #ffffff;
  border-color: #003087;
  box-shadow: 0 0 0 0.0625rem #003087 inset;
  color: #003087; }

button.ui.button.paypal {
  background: #ffffff;
  border-color: #0070ba;
  color: #0070ba;
  box-shadow: 0 0 0 0.0625rem rgba(0, 0, 0, 0) inset;
  border-radius: 1.5rem;
  border: 0.0625rem solid #0070ba; }

.ui.horizontal.segments > .segment {
  display: flex;
  align-content: center;
  justify-content: center;
  border-left: none; }

.ui.dropdown:not(.button) > .default.text {
  color: #7c7c7c; }

.ui.image.header.header-profile-navigation .content {
  color: #0070ba; }

.ui.item.simple.dropdown.navigation-profile:hover
.menu.transition.right
.item:hover {
  border-bottom: 0 !important;
  background-color: #fff !important;
  border-radius: 0; }

.ui.item.simple.dropdown.navigation-profile:hover .menu.transition.right .item {
  align-self: baseline; }

.ui.item.simple.dropdown.navigation-profile:hover .menu.transition.right {
  margin-top: 0; }

.ui.item.simple.dropdown.navigation-profile:hover {
  background-color: #fff;
  border-radius: 0; }

.ui.avatar.image.gorfpaytoken {
  width: 1.5em;
  height: 1.5em;
  margin: 0; }

.gorfpaytokens-count.x24 {
  font-size: 24px;
  margin: 15px 0; }

.gorfpaytokens-count.x20 {
  font-size: 20px;
  margin: 5px 0; }

.gorfpaytokens-count {
  display: flex;
  margin: 0px auto;
  align-items: center;
  justify-content: center;
  font-size: 16px; }

.ui.avatar.image.gorfpaytoken2 {
  width: 1em;
  height: 1em;
  margin: 0; }

.ui[class*="middle aligned"].grid > .column:not(.row).toTop {
  align-self: flex-start !important;
  max-width: 450px; }

img.logo {
  padding-bottom: 25px; }

main.App-content {
  min-height: calc(100vh - 50px);
  display: flex; }
  main.App-content div.grid.center {
    min-width: 450px;
    margin: auto; }
    main.App-content div.grid.center.transfer-form {
      min-width: 450; }
      @media (max-width: 500px) {
        main.App-content div.grid.center.transfer-form {
          min-width: 0; } }
    @media (max-width: 500px) {
      main.App-content div.grid.center.signUp {
        min-width: 0; } }
    @media (max-width: 520px) {
      main.App-content div.grid.center.purchase-form {
        min-width: 0; } }

#footer {
  border-radius: 0;
  border-bottom: none;
  height: 49px;
  margin: 0;
  padding: 14px;
  width: 100%;
  z-index: 1000; }
  #footer div {
    margin: 0;
    padding: 0; }
  #footer div:first-child {
    justify-content: left; }

/* .moonOn{
    color:white !important;
    a{
        color: white !important;
    }
    i{
        color:white !important;
    }
    .header{
        color:white !important;
    }
    .item{
        color:white !important;
    }
    .description{
        color: white !important;
    }
} */
a.item {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }

.sidebar-wrapper .item {
  transition: 0ms !important; }

.backColor {
  background-color: #262440 !important;
  /*  button{
        background-color: #262440 !important;
        color:white !important;
        border: 0.0625rem solid white !important;
    } */
  scrollbar-color: white transparent;
  /*  ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      } */ }
  .backColor .ui.item.simple.dropdown.navigation-profile:hover
.menu.transition.right
.item:hover,
  .backColor .actions,
  .backColor a:hover,
  .backColor .ui.horizontal.segments {
    background-color: #262440 !important; }
  .backColor a,
  .backColor i,
  .backColor .header,
  .backColor td,
  .backColor .description,
  .backColor th,
  .backColor a.item,
  .backColor .ui.menu .ui.dropdown .menu > .item,
  .backColor label,
  .backColor .item,
  .backColor .scaptcha-anchor-label {
    color: white !important; }
  .backColor .menu,
  .backColor #footer,
  .backColor input,
  .backColor .item,
  .backColor .content,
  .backColor .segment,
  .backColor table,
  .backColor .modal .header,
  .backColor .clndr-cell {
    background-color: #262440 !important;
    color: white !important; }
  .backColor .navigation,
  .backColor .sidebar-wrapper__under-logo {
    border-bottom: 2px solid white !important; }
  .backColor td,
  .backColor th,
  .backColor table,
  .backColor #footer,
  .backColor .actions {
    border-color: white !important; }
  .backColor #footer {
    border-left: none;
    border-right: none; }
  .backColor .message,
  .backColor input,
  .backColor textarea,
  .backColor .label,
  .backColor .button.paypal {
    color: white !important;
    background-color: #262440 !important;
    border: 2px solid white !important; }
  .backColor .circular.button,
  .backColor .scaptcha-anchor-container {
    color: white !important;
    background-color: #262440 !important;
    border: 1px solid white !important; }
  .backColor .sidebar-wrapper .ui.menu a.item,
  .backColor span {
    color: white !important; }
  .backColor .sidebar-wrapper .ui.menu a.item:hover {
    color: white !important;
    background: #76c2fd !important; }
  .backColor .sidebar.menu {
    border-right: 1px solid white; }
  .backColor .sidebar-wrapper .ui.menu a.item.active {
    background: #0070ba !important;
    color: white !important; }
  .backColor .sidebar-wrapper .ui.menu a.item.active:hover {
    color: white !important; }
  .backColor #menuToggle span {
    background-color: white; }
  .backColor .navigation a.item {
    padding: 7px 2px !important; }
  .backColor .navigation a.item.active {
    border-width: 2px !important; }
  .backColor .column .card {
    border: 1px solid white;
    border-radius: 10px !important; }
  .backColor .column .content {
    border-radius: 10px !important; }
  .backColor .button.black,
  .backColor .button.teal {
    background-color: #76c2fd !important; }
  .backColor .mobile-table__item .checkbox label::before,
  .backColor .segment .checkbox label::before {
    background-color: #76c2fd; }
  .backColor .mobile-table__item .ui.toggle.checkbox label:hover::before,
  .backColor .mobile-table__item .ui.checkbox input:focus ~ label::before,
  .backColor .segment .ui.toggle.checkbox label:hover::before,
  .backColor .segment .ui.checkbox input:focus ~ label::before {
    background-color: #76c2fd; }
  .backColor .highcharts-background {
    fill: #262440 !important; }
  .backColor .highcharts-tracker-line {
    color: white; }
  .backColor text {
    fill: white !important; }
  .backColor .dropdown.search {
    background: #262440 !important;
    color: white !important;
    border: 2px solid white !important; }
    .backColor .dropdown.search.ui.search.dropdown > input.search {
      background: #262440 !important;
      color: white !important; }
  .backColor svg {
    fill: white !important;
    stroke: white !important; }
  .backColor .react-calendar__month-view__weekdays {
    background-color: #262440 !important; }
  .backColor .react-calendar__navigation {
    background-color: #262440 !important;
    margin-bottom: 0 !important;
    padding-bottom: 1em !important; }
  .backColor .react-date-picker input,
  .backColor .react-date-picker button {
    border: none !important; }
  .backColor .selection.dropdown {
    color: white !important;
    background-color: #262440 !important;
    border: 2px solid white; }
    .backColor .selection.dropdown div {
      color: white !important; }
  .backColor .modalLinkTree .dropdown {
    border: 2px solid white; }
    .backColor .modalLinkTree .dropdown i {
      border: none; }
  .backColor .modalLinkTree .label {
    background-color: #262440 !important;
    border: 1px solid white !important;
    border-right: none; }
  .backColor i.icon {
    width: auto;
    margin: 0; }
  .backColor .ui.horizontal.segments:first-child {
    border-radius: 0;
    border: 1px solid white; }
  .backColor .backups-list .ui.icon.button,
  .backColor .changeColorBackground {
    background-color: #76c2fd; }
  .backColor .announcements-list .ui.icon.button.changeColorBackground:hover {
    background-color: #76c2fd; }
  .backColor .visible.content {
    background-color: transparent !important; }
  .backColor .home .divider.text {
    color: #76c2fd !important; }
  .backColor .LinkTreeComponent {
    color: white !important; }
  .backColor .ui.form .field.field input:autofill {
    box-shadow: none !important; }
  .backColor ::-webkit-scrollbar {
    width: 3px;
    height: 3px; }
  .backColor ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 50px; }
  .backColor ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 53px; }
  .backColor ::-webkit-scrollbar-track:hover {
    background: #666666; }
  .backColor ::-webkit-scrollbar-track:active {
    background: #333333; }
  .backColor ::-webkit-scrollbar-corner {
    background: transparent; }
  .backColor .transfer .checkbox label::before {
    background-color: white !important; }
  .backColor .navigation a.item.active {
    border-bottom: 2px solid #76c2fd !important; }
  .backColor .navigation .ui.secondary.menu a.item:hover {
    border-color: #76c2fd !important; }
  .backColor .dropdown.search.ui.search.dropdown > input.search {
    background-color: transparent !important; }
  .backColor .menu.transition {
    border: 2px solid white; }
  .backColor .modal.transition .menu.transition .item {
    border: 1px solid white; }
  .backColor .modal.transition .menu.transition .item:last-child {
    border: none; }
  .backColor .modal.transition .menu.transition .item:hover {
    font-weight: bold; }
  .backColor .visible.menu.transition .item:hover,
  .backColor .clndr-cell:hover {
    font-weight: 700; }
  .backColor .ui.selection.dropdown:hover {
    border-color: white; }
  .backColor .admin-transactions-list .button {
    background-color: #76c2fd; }
  .backColor .button .hidden.content {
    background-color: transparent !important; }
  .backColor .react-date-picker__inputGroup:focus {
    outline: none;
    border: none; }
  .backColor .react-calendar__viewContainer button:hover {
    font-weight: 700; }
  .backColor .react-calendar__viewContainer .react-calendar__month-view__days__day--neighboringMonth abbr {
    color: gray !important; }
  .backColor .react-calendar__viewContainer .react-calendar__month-view__days__day--weekend abbr {
    color: tomato !important; }
  .backColor .react-calendar__viewContainer .react-calendar__month-view__days__day {
    background: #262440 !important; }
    .backColor .react-calendar__viewContainer .react-calendar__month-view__days__day abbr {
      color: white; }
  .backColor .react-calendar__navigation button {
    color: white !important; }
  .backColor .react-calendar__navigation button:enabled:hover {
    background-color: transparent !important; }
  .backColor .react-calendar__year-view .react-calendar__year-view__months__month {
    background: #262440 !important;
    color: white !important; }
  .backColor .react-calendar__navigation button:enabled:focus {
    background-color: transparent !important; }
  .backColor .ui.pointing.secondary.menu .item + .item {
    margin-left: 15px; }
  .backColor .ui.pointing.secondary.menu .item.active {
    border-color: #76c2fd; }
  .backColor .ui.pointing.secondary.menu .item:hover {
    border-bottom: 1px solid #76c2fd !important; }
  .backColor .sidebar-wrapper .ui.menu a.item {
    border-bottom: 1px solid gray !important; }

.signIn {
  height: 100vh !important;
  padding: 0 10px !important; }

.signUp {
  height: 100vh !important;
  padding: 0 10px !important; }

.signIn .logo,
.signUp .logo {
  margin-bottom: 15px; }

.backColor .signIn .ui.segments,
.backColor .signUp .ui.segments {
  border: 2px solid white !important; }

.burger span {
  display: block;
  width: 33px;
  height: 4px;
  position: relative;
  background: black;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px; }

.burger span + span {
  margin-top: 5px; }

#menuToggle {
  cursor: pointer; }

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  z-index: 1000; }
