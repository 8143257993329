button, hr, input {
    overflow: visible
}

progress, sub, sup {
    vertical-align: baseline
}

[type=checkbox], [type=radio], legend {
    box-sizing: border-box;
    padding: 0
}

@font-face {
    font-family: PayPalSansSmall-Regular;
    font-style: normal;
    font-display: swap;
    src: url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansSmall-Regular.woff2) format('woff2'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansSmall-Regular.woff) format('woff'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansSmall-Regular.eot?#iefix) format('embedded-opentype'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansSmall-Regular.svg) format('svg')
}

@font-face {
    font-family: PayPalSansBig-Light;
    font-style: normal;
    font-display: swap;
    src: url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Light.woff2) format('woff2'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Light.woff) format('woff'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Light.eot?#iefix) format('embedded-opentype'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Light.svg) format('svg')
}

@font-face {
    font-family: PayPalSansBig-Regular;
    font-style: normal;
    font-display: swap;
    src: url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Regular.woff2) format('woff2'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Regular.woff) format('woff'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Regular.eot?#iefix) format('embedded-opentype'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Regular.svg) format('svg')
}

@font-face {
    font-family: PayPalSansBig-Medium;
    font-style: normal;
    font-display: swap;
    src: url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Medium.woff2) format('woff2'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Medium.woff) format('woff'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Medium.eot?#iefix) format('embedded-opentype'), url(https://www.paypalobjects.com/paypal-ui/fonts/PayPalSansBig-Medium.svg) format('svg')
}

html {
    box-sizing: border-box;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%
}

*, :after, :before {
    box-sizing: inherit
}

body, html {
    height: 100%;
    font-family: PayPalSansBig-Regular, Helvetica Neue, Arial, sans-serif;
    color: #2c2e2f
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

details, main {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

hr {
    box-sizing: content-box;
    height: 0
}

code, kbd, pre, samp {
    font-family: monospace;
    font-size: 1em
}

a {
    background-color: transparent
}

abbr[title] {
    border-bottom: none;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

b, strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    border-style: none
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button, select {
    text-transform: none
}

[type=submit], [type=button], [type=reset], button {
    -webkit-appearance: button
}

[type=submit]::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=submit]:-moz-focusring, [type=button]:-moz-focusring, [type=reset]:-moz-focusring, button:-moz-focusring {
    outline: ButtonText dotted 1px
}

fieldset {
    padding: .35em .75em .625em
}

legend {
    color: inherit;
    display: table;
    max-width: 100%;
    white-space: normal
}

textarea {
    overflow: auto
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

[hidden], template {
    display: none
}

body, html {
    height: 100%;
    font-family: PayPalSansBig-Regular, Helvetica Neue, Arial, sans-serif;
    color: #2c2e2f;
    background-color: #F5F7FA;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
